import { download_helper } from "../supports/download_helper";

$(document).on('turbo:load', function() {
  
  $("body").delegate("#issue_download_all_file", "click", function (e){
    const issue_id = this.getAttribute('data-issue');
    download_all_file_issue(issue_id);
  });
  $("body").delegate(".show_form_filter", "click", function (e){
    $(".form_filter").toggleClass("d-none");
  });

  $('#lstTagId').select2({
    placeholder: I18n.t("issues.form_filter.tags"),
  }).on("change", function(e){
    filter_with_issue();
  });

  $(document).click(function(e) {
    if (!$(e.target).parent().hasClass("show_form_filter") && !$(e.target).parent().hasClass("select2-selection__choice__remove")
     && !$(e.target).closest(".form_filter").length) {
      $(".form_filter").addClass("d-none");
    }
  });

  $('.form_filter .dropdown-item').on('click', function(event) {
    event.stopPropagation();
  });

  $("body").delegate("#clear_filter_board", "click", function () {
    let option_list = $(".form_filter").find("input[type='checkbox']");
    $.each(option_list, function( index, col ) {
      $(col).prop("checked", false);
    });

    $('.filter_assignee_in_issue').val(null).trigger('change.select2');
    $('#lstTagId').val(null).trigger('change.select2');

    $(".show_form_filter").children().first().removeClass("d-none")
    $(".show_form_filter").children().last().addClass("d-none")
    $(".form_filter").children().find(".border-primary").removeClass("border-primary")
    filter_with_issue();
  });

  $('.form_filter input[type=checkbox]').change(function() {
    filter_with_issue();
  });

  $(".filter_assignee_in_issue").each(function(){
    $(this).select2({
      placeholder: I18n.t("issues.form_filter.assignee"),
    }).on("change", function(e){
      filter_with_issue();
    })
  });

  $("body").delegate("#filter_issue_name", "click", function (e){
    filter_with_issue();
  });

  $("body").delegate(".sort_issue_button", "click", function (e){
    remove_active_class(this)
    filter_with_issue();
  });

  $("body").delegate(".suggest_issue_name", "keydown", function (event){
    if (event.keyCode == 13) {
      filter_with_issue();
    }
  });

  function remove_active_class(el) {
    // $("#sort_issue_options").find(".sort_issue_button").removeClass("active")
    $(el).toggleClass("active")
  }

  function get_value_sort_issue() {
    let el = $("#sort_issue_options").find(".sort_issue_button.active")

    if(el.length > 0){
      $(".form_filter_in_issue #sort_issue_icon").attr("style", "filter: invert(38%) sepia(97%) saturate(1443%) hue-rotate(182deg) brightness(99%) contrast(92%)")
      return el.attr("value")
    }

    $(".form_filter_in_issue #sort_issue_icon").attr("style", "")
    return null
  }

  

  if ($("#issues_table").length > 0) {
    $("#issues_table")[0].addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
      if (this.scrollHeight - this.scrollTop - this.clientHeight <= 0) {
        let filter_options = get_filter_value();
        let next_page_number = parseInt($("#issues_table").find(".issue_row_in_table").attr("data-page"));
        if (next_page_number > 0) {
          $.ajax({
            type: "GET",
            url: $("#center_issue_menu_tab").find(".issue_list_link").attr('href'),
            dataType: "script",
            data: {
              page: next_page_number,
              filter_options: filter_options,
              sort: get_value_sort_issue(),
            },
            success: function (data, textStatus, jqXHR) {
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR.responseText)
            }
          })
        }
      }
    }, false);
  }



  function filter_with_issue(sort) {
    let filter_options = get_filter_value()
    $.ajax({
      type: 'GET',
      url: $("#center_issue_menu_tab").find(".active").attr('href'),
      dataType: 'script',
      data: {
        filter_options: filter_options,
        sort: get_value_sort_issue(),
      },
      success: function (data, textStatus, jqXHR) {
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });
  };

  function get_filter_value() {
    let find_issue = $(".suggest_issue_name").val().trim();
    let filter_assignee = $(".filter_assignee_in_issue").val();

    if (find_issue != "") {
      var img = $(".form_search_in_issue").find('img');
      img.attr('src', $(".suggest_issue_name").data('search-filter'));
      img.addClass('img_search');
    }
    else {
      var img = $(".form_search_in_issue").find('img');
      img.attr('src', $(".suggest_issue_name").data('search'));
      img.removeClass('img_search');
    }

    let filter_date = $('input[name="filter_checkbox_date"]:checked').map(function() {
      return this.value;
    }).get();

    let filter_stage = $('input[name="filter_checkbox_stage"]:checked').map(function() {
      return this.value;
    }).get();

    let filter_priority = $('input[name="filter_checkbox_priority"]:checked').map(function() {
      return this.value;
    }).get();

    let filter_tag = $("#lstTagId").val();

    if ($('.form_filter input:checked').length > 0 || filter_assignee.length > 0 || filter_tag.length > 0) {
      $(".show_form_filter").children().first().addClass("d-none")
      $(".show_form_filter").children().last().removeClass("d-none")
    }
    else {
      $(".show_form_filter").children().first().removeClass("d-none")
      $(".show_form_filter").children().last().addClass("d-none")
    }

    if (filter_date.length > 0){
      $('.date_filtered').addClass('border-primary')
    }else{
      $('.date_filtered').removeClass('border-primary')
    }

    if (filter_stage.length > 0){
      $('.stage_filtered').addClass('border-primary')
    }else{
      $('.stage_filtered').removeClass('border-primary')
    }

    if (filter_priority.length > 0){
      $('.priority_filtered').addClass('border-primary')
    }else{
      $('.priority_filtered').removeClass('border-primary')
    }

    return {
      filter_assignee: filter_assignee,
      filter_date: filter_date,
      filter_stage: filter_stage,
      filter_priority: filter_priority,
      filter_tag: filter_tag,
      find_issue: find_issue,
    }
  }
});


function  download_all_file_issue(issue_id){
  $.ajax({
    type: 'POST',
    url: "/issues/get_list_file_issue",
    dataType: 'JSON',
    data: {
      issue_id: issue_id,    
    },
    success: function (data, textStatus, jqXHR) {      
      download_ulrs(data.files,data.issue_name);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log("AJAX Error: #{textStatus}")
    }
  });
}

function download_ulrs(files,issuename) {
  download_helper.download_list_file(files,[],issuename + ".zip");
}